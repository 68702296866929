// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/validations.ts"
);
import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { isValid as isValidDate, format, parse } from 'date-fns';

import * as yup from 'yup';
import {
  REPORT_UNIT_CODES,
  COUNTRIES_ALPHA2_LIST,
  GENDER_VALUES,
  EMPLOYEE_TYPE,
  YESNO,
  ENTITY_TYPE,
  RECALL_TYPE,
  WORK_INJURY,
  REJECT_ACCEPT,
} from './reportUtilities';
import { CURRENCY_ALPHA_LIST } from './globalConstant';

export const validationRules = {
  firstname: yup
    .string()
    .max(100, 'First_Name_Must_Be_At_Most_100_Characters')
    .required('First_Name_Is_Required'),
  lastname: yup
    .string()
    .max(100, 'Last_Name_Must_Be_At_Most_100_Characters')
    .required('Last_Name_Is_Required'),
  companyname: yup
    .string()
    .max(100, 'Company_Name_Must_Be_At_Most_100_Characters')
    .required('Company_Name_Is_Required'),
  phonenumber: yup
    .string()
    .max(100, 'Phone_Number_Must_Be_At_Most_100_Characters')
    .required('Phone_Number_Is_Required')
    .matches(/^[0-9]*$/, {
      message: 'Please_Enter_Valid_Phone_Number',
      excludeEmptyString: false,
    }),
  email: yup
    .string()
    .email('Enter_Email')
    .max(70, 'Email_Characters_Limit')
    .required('Email_Required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, {
      message: 'Please_Enter_Valid_Email',
    }),
  optionalEmail: yup
    .string()
    .email('Enter_A_Valid_Email')
    .max(70, 'Email_Must_Be_At_Most_70_Characters'),
  optionalPhone: yup
    .string()
    .max(100, 'Phone_Number_Must_Be_At_Most_100_Characters')
    .matches(/^[0-9]*$/, {
      message: 'Please_Enter_Valid_Phone_Number',
      excludeEmptyString: false,
    }),
  password: yup
    .string()
    .min(9, 'Password_Must_Be_At_Least_9_Characters')
    .max(50, 'Password_Must_Be_At_Most_50_Characters')
    .required('Password_Is_Required'),
  otpCode: yup
    .string()
    .max(20, 'OTP_Must_Be_At_Most_20_Characters')
    .required('OTP_Is_Required'),
  subscribed: yup.boolean(),
  collectionName: yup
    .string()
    .min(5, 'Collection_Name_Must_Be_At_Least_5_Characters')
    .max(25, 'Collection_Name_Must_Be_At_Most_25_Characters')
    .required('This_Field_Is_Required'),
  moduleName: yup
    .string()
    .min(5, 'Module_Name_Must_Be_At_Least_5_Characters')
    .max(25, 'Module_Name_Must_Be_At_Most_25_Characters')
    .required('This_Field_Is_Required'),
  requiredString: yup.string().required('Field_Required'),
  boolean: yup.boolean(),
  positiveNumber: yup
    .number()
    .required('This_Field_Is_Required')
    .positive('The_Number_Must_Be_Greater_Than_0')
    .integer('Should_Be_An_Integer_Value')
    .test(
      'Is_Positive',
      'The_Number_Must_Be_Greater_Than_0',
      (value) => value > 0,
    ),
  deadlineDay: yup
    .number()
    .required('This field is required')
    .positive('The number must be greater than 0!')
    .integer('Should be an integer value')
    .min(1, 'The number must be at least 1')  
    .max(28, 'The number must be at most 28')  
    .test(
      'Is positive?',
      'The number must be greater than 0!',
      (value) => value > 0
    ),
  requiredValue: yup
    .number()
    .required('This_Field_Is_Required')
    .positive('The_Number_Must_Be_Greater_Than_0')
    .test(
      'Is_Decimal_And_Positive',
      'The_Number_Must_Be_A_Decimal_Greater_Than_0',
      (value) => value > 0,
    ),
  organizationCode: yup
    .string()
    .required('This_Field_Is_Required')
    .matches(/^([A-Za-z0-9]+(?:\s[A-Za-z0-9]+)*)?$/, {
      message:
        'Only_Characters_And_Numbers_Separated_By_Single_Space_Are_Allowed',
    }),
  contactPerson: yup
    .string()
    .required('This_Field_Is_Required')
    .matches(/^[A-Za-z\s]+$/, {
      message: 'Only_Characters_Are_Allowed',
    }),
  contactPhone: yup
    .string()
    .max(100, 'Phone_Number_Must_Be_At_Most_100_Characters')
    .required('Phone_Number_Is_Required')
    .matches(/^[0-9]*$/, {
      message: 'Please_Enter_Valid_Phone_Number',
      excludeEmptyString: false,
    }),
  contactEmail: yup
    .string()
    .email('Enter_A_Valid_Email')
    .max(70, 'Email_Must_Be_At_Most_70_Characters')
    .required('Email_Is_Required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, {
      message: 'Please_Enter_Valid_Email',
    }),
  weightage: yup
    .number()
    .required('Field_Required')
    .positive('Weightage_Value')
    .integer('Integer_Value')
    .test('Is_Positive', 'Weightage_Value', (value) => value > 0)
    .test('Is_Maximum', 'Maximum_Weightage', (value) => value <= 100),
  maxAllowed: yup
    .number()
    .required('Field_Required')
    .positive('Max_Allowed_Value')
    .integer('Integer_Value')
    .test('Is_Positive', 'Max_Allowed_Value', (value) => value > 0)
    .test('Is_Maximum', 'Test_Max_Value_Allowed', (value) => value <= 99999999),
  alphaNumeric: yup
    .string()
    .required('This_Field_Is_Required')
    .max(40, 'Must_Be_At_Most_40_Characters')
    .matches(
      /^[a-zA-Z0-9_]*$/,
      'Invalid_Characters_Only_Alphanumeric_Characters_Are_Allowed',
    ),
  optionalString: yup
    .string()
    .max(250, 'This_Field_Must_Be_At_Most_250_Characters'),
  arrayOfStrings: yup.array().of(yup.string()),
  requiredArrayOfStrings: yup
    .array()
    .of(yup.string())
    .min(1, 'Select_At_Least_One_Option')
    .required('Field_Required'),
  arrayOfSelectValues: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .min(1, 'You_Have_To_Select_At_Least_One_Option')
    .required('This_Field_Is_Required'),
  optionalPositiveNumber: yup
    .number()
    .positive('The_Number_Must_Be_Greater_Than_0')
    .integer('Should_Be_An_Integer_Value')
    .test(
      'Is_Positive',
      'The_Number_Must_Be_Greater_Than_0',
      (value) => !!value && value >= 0,
    ),
  requiredNumber: yup.number().required('This_Field_Is_Required'),
};

const validFileExtensions = {
  image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
  excel: ['xls', 'xlsx'],
};

const validScopes = ['Scope 1', 'Scope 2', 'Scope 3'];

export const isValidFileType = (
  fileName: any,
  fileType: keyof typeof validFileExtensions,
) => {
  return (
    fileName &&
    validFileExtensions?.[fileType]?.indexOf(fileName?.split('.').pop()) > -1
  );
};

export const validateOptionalString = (value: any, errorMessage?: string) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  return {
    isValid: typeof trimmedValue === 'string',
    errorMessage,
    data: trimmedValue,
  };
};

export const validateRequiredString = (value: any, errorMessage?: string) => {
  return {
    isValid: typeof value === 'string' && !!`${value}`.trim(),
    errorMessage,
    data: value,
  };
};

export const validateEmissionScope = (value: any, errorMessage?: string) => {
  // Trim the value if it's a string
  const trimmedValue = typeof value === 'string' ? value.trim() : value;

  return {
    isValid:
      typeof trimmedValue === 'string' && validScopes.includes(trimmedValue),
    errorMessage,
    data: trimmedValue,
  };
};

export const validateUnits = (
  value: any,
  optional = true,
  errorMessage?: string,
) => {
  // Trim the value if it's a string
  const trimmedValue = typeof value === 'string' ? value.trim() : value;

  // if unit is optional
  if (optional && !trimmedValue) {
    return {
      isValid: true,
      errorMessage,
      data: trimmedValue,
    };
  }
  return {
    isValid:
      typeof trimmedValue === 'string' &&
      REPORT_UNIT_CODES.includes(trimmedValue),
    data: trimmedValue,
    errorMessage,
  };
};

export const validateCountry = (
  value: any,
  optional = false,
  errorMessage?: string,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  // if country is optional
  if (optional && !trimmedValue) {
    return {
      isValid: true,
      data: trimmedValue,
      errorMessage,
    };
  }
  return {
    isValid:
      typeof trimmedValue === 'string' &&
      COUNTRIES_ALPHA2_LIST.includes(trimmedValue),
    data: trimmedValue,
    errorMessage,
  };
};

export const validateCurrency = (
  value: any,
  optional = false,
  errorMessage?: string,
) => {
  // if Currency is optional
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && CURRENCY_ALPHA_LIST.includes(value),
    data: value,
    errorMessage,
  };
};

export const isAlphanumeric = (value: any) => {
  // Check if the value contains only alphanumeric characters
  return /^[a-zA-Z0-9]+$/.test(value);
};

export const isUnique = (value: any, list: any[]) => {
  // Check if the value is unique in the list
  return !list.includes(value);
};

export const validateGender = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  // if gender is optional
  if (optional && !trimmedValue) {
    return {
      isValid: true,
      data: trimmedValue,
      errorMessage,
    };
  }
  return {
    isValid:
      typeof trimmedValue === 'string' && GENDER_VALUES.includes(trimmedValue),
    data: trimmedValue,
    errorMessage,
  };
};

export const validateEntityType = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && ENTITY_TYPE.includes(value),
    data: value,
    errorMessage,
  };
};
export const validateEmpType = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && EMPLOYEE_TYPE.includes(value),
    data: value,
    errorMessage,
  };
};

export const validateDifferntlyEnable = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && YESNO.includes(value),
    data: value,
    errorMessage,
  };
};
export const validateAppealedAuth = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && REJECT_ACCEPT.includes(value),
    data: value,
    errorMessage,
  };
};
export const validateWorkInjury = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && WORK_INJURY.includes(value),
    data: value,
    errorMessage,
  };
};
export const validateRecallType = (
  value: string,
  optional = false,
  errorMessage?: string,
) => {
  if (optional && !value) {
    return {
      isValid: true,
      data: value,
      errorMessage,
    };
  }
  return {
    isValid: typeof value === 'string' && RECALL_TYPE.includes(value),
    data: value,
    errorMessage,
  };
};

export const validateRequiredOnDependent = (
  value: any,
  isRequired: boolean,
  errorMessage?: string,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  if (isRequired) {
    return {
      isValid: !!trimmedValue,
      data: trimmedValue,
      errorMessage,
    };
  }
  return {
    isValid: true,
    data: trimmedValue,
    errorMessage,
  };
};

export const validateAlphanumericPrimaryKey = (
  value: any,
  list: any[],
  errorMessage?: string,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  const isUniqueItem = isUnique(trimmedValue, list);
  const isAlphanumericItem = isAlphanumeric(trimmedValue);
  // validate if value is alphanumeric and unique in the list
  return {
    isValid: isUniqueItem && isAlphanumericItem && !!trimmedValue,
    data: trimmedValue,
    errorMessage,
  };
};

export const validatePrimaryKey = (
  value: any,
  list: any[],
  errorMessage?: string,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  const isUniqueItem = isUnique(trimmedValue, list);
  // validate if value is unique in the list
  return {
    isValid: isUniqueItem && !!trimmedValue,
    data: trimmedValue,
    errorMessage,
  };
};

export const validateItemInList = (
  value: any,
  list: any[],
  errorMessage?: string,
  optional = false,
  caseInsensitive = false,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  let listToCheck = list;

  let valueToCheck = trimmedValue;
  if (caseInsensitive) {
    valueToCheck = trimmedValue?.toLowerCase();
    listToCheck = list.map((item) => item?.toLowerCase());
  }
  if (optional) {
    let isValid = valueToCheck ? listToCheck.includes(valueToCheck) : true;
    return {
      isValid,
      data: trimmedValue,
      errorMessage,
    };
  }
  return {
    isValid: listToCheck?.includes(valueToCheck),
    data: trimmedValue,
    errorMessage,
  };
};

export const validateItemInListBrsr = (
  value: any,
  list: any[],
  errorMessage = 'Invalid value',
  optional = false,
  caseInsensitive = false,
) => {
  try {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    let listToCheck = list;
    let valueToCheck = trimmedValue;

    if (caseInsensitive) {
      valueToCheck = trimmedValue?.toLowerCase();
      listToCheck = list.map((item) => item?.toLowerCase());
    }

    // If the field is optional and the value is empty or null, return as valid
    if (optional && (!valueToCheck || valueToCheck === '')) {
      return {
        isValid: true,
        data: trimmedValue,
        errorMessage: null,
      };
    }

    const isValid = listToCheck.includes(valueToCheck);
    return {
      isValid,
      data: trimmedValue,
      errorMessage: isValid ? null : errorMessage,
    };
  } catch (error) {
    console.error('Error validating item in list:', error);
    return {
      isValid: false,
      data: value,
      errorMessage: 'An error occurred during validation.',
    };
  }
};

export const validateItemInListBrsrFacility = (
  value: any,
  list: { department: any[]; facility: string }[],
  department: any[] = [],
  errorMessage = 'Invalid value',
  optional = false,
  caseInsensitive = false,
) => {
  try {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    let valueToCheck = trimmedValue;

    if (caseInsensitive) {
      valueToCheck =
        typeof valueToCheck === 'string'
          ? valueToCheck.toLowerCase()
          : valueToCheck;
    }

    // If the field is optional and the value is empty or null, return as valid
    if (optional && (!valueToCheck || valueToCheck === '')) {
      return {
        isValid: true,
        data: trimmedValue,
        errorMessage: null,
      };
    }

    const isValid = list.some((item) => {
      const facilityMatches = caseInsensitive
        ? item.facility.toLowerCase() === valueToCheck
        : item.facility === valueToCheck;

      // const departmentMatches = item.department.some(dep => department.includes(dep));
      const departmentMatches =
        Array.isArray(item.department) &&
        ((department.length === 0 &&
          (item.department === null || item.department.length === 0)) ||
          item.department.some((dep) => department.includes(dep)));

      return facilityMatches && departmentMatches;
    });

    return {
      isValid,
      data: trimmedValue,
      errorMessage: isValid ? null : errorMessage,
    };
  } catch (error) {
    console.error('Error validating item in list:', error);
    return {
      isValid: false,
      data: value,
      errorMessage: 'An error occurred during validation.',
    };
  }
};

export const validateDateFormat = (
  value: any,
  errorMessage = '',
  endTime = false,
  optional = false,
) => {
  try {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    if (optional && !trimmedValue) {
      return {
        isValid: true,
        data: null,
        errorMessage,
      };
    }
    const expectedDateFormat = 'dd/MM/yyyy';
    const excelDate = new Date((trimmedValue - (25567 + 2)) * 86400 * 1000);
    const formattedDate = format(excelDate, expectedDateFormat);
    const parsedDate = parse(formattedDate, 'dd/MM/yyyy', new Date());
    const formatedDate = format(
      parsedDate,
      endTime
        ? "yyyy-MM-dd'T23:59:00.001+00:00'"
        : "yyyy-MM-dd'T00:00:00.001+00:00'",
    );
    let isValid = !!trimmedValue && isValidDate(parsedDate);
    return {
      isValid,
      data: formatedDate,
      errorMessage,
    };
  } catch (error) {
    return {
      isValid: optional,
      data: value,
      errorMessage,
    };
  }
};

export const validateReportDateFormat = (
  value: any,
  errorMessage = '',
  { fileYear, fileMonth }: { fileYear?: number; fileMonth?: number },
) => {
  try {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    const expectedDateFormat = 'dd/MM/yyyy';
    const excelDate = new Date((trimmedValue - (25567 + 2)) * 86400 * 1000);
    const formattedDate = format(excelDate, expectedDateFormat);
    const parsedDate = parse(formattedDate, 'dd/MM/yyyy', new Date());
    const reportDay = Number(format(parsedDate, 'd'));
    const reportYear = Number(format(parsedDate, 'yyyy'));
    const reportMonth = Number(format(parsedDate, 'M'));
    const reportMonthName = format(parsedDate, 'MMMM');
    const formatedDate = format(parsedDate, "yyyy-MM-dd'T00:00:00.001+00:00'");
    let isValid = !!trimmedValue && isValidDate(parsedDate);
    console.log({ isValid, reportYear, reportMonth, fileYear });
    if (fileYear && fileMonth) {
      isValid =
        !!trimmedValue &&
        isValidDate(parsedDate) &&
        reportYear === fileYear &&
        reportMonth === fileMonth;
    }
    return {
      isValid,
      data: {
        startDate: formattedDate,
        value: trimmedValue,
        excelDate: `${excelDate}`,
        reportDay,
        reportYear,
        reportMonth,
        reportMonthName,
        formatedDate,
      },
      errorMessage,
    };
  } catch (error) {
    return {
      isValid: false,
      data: value,
      errorMessage,
    };
  }
};

export const validateEndDateFormat = (
  value: any,
  errorMessage = '',
  { fileYear, fileMonth }: { fileYear?: number; fileMonth?: number },
) => {
  try {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    const expectedDateFormat = 'dd/MM/yyyy';
    const excelDate = new Date((trimmedValue - (25567 + 2)) * 86400 * 1000);
    const formattedDate = format(excelDate, expectedDateFormat);
    const parsedDate = parse(formattedDate, 'dd/MM/yyyy', new Date());
    const endDay = Number(format(parsedDate, 'd'));
    const endYear = Number(format(parsedDate, 'yyyy'));
    const endMonth = Number(format(parsedDate, 'M'));
    const endMonthName = format(parsedDate, 'MMMM');
    const formatedDate = format(parsedDate, "yyyy-MM-dd'T00:00:00.001+00:00'");
    let isValid = !!trimmedValue && isValidDate(parsedDate);
    console.log({ isValid, endYear, endMonth, fileYear });
    if (fileYear && fileMonth) {
      isValid =
        !!trimmedValue &&
        isValidDate(parsedDate) &&
        endYear === fileYear &&
        endMonth === fileMonth;
    }
    return {
      isValid,
      data: {
        endDate: formattedDate,
        value: trimmedValue,
        excelDate: `${excelDate}`,
        endDay,
        endYear,
        endMonth,
        endMonthName,
        formatedDate,
      },
      errorMessage,
    };
  } catch (error) {
    return {
      isValid: false,
      data: value,
      errorMessage,
    };
  }
};
export const validateYear = (
  value: any,
  errorMessage?: string,
  optional = false,
) => {
  try {
    if (optional && !value) {
      return {
        isValid: true,
        data: null,
        errorMessage,
      };
    }
    const yearRegex = /^(?:19|20)\d{2}$/;
    const isValid =
      !!value && yearRegex.test(value) && !isNaN(parseFloat(value));
    return {
      isValid,
      data: parseFloat(value),
      errorMessage,
    };
  } catch (error) {
    return {
      isValid: false,
      data: value,
      errorMessage,
    };
  }
};

export const validatePositiveNumber = (value: any, errorMessage?: string) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  try {
    const isValid =
      !isNaN(parseFloat(trimmedValue)) && parseFloat(trimmedValue) >= 0;
    return {
      isValid,
      data: parseFloat(trimmedValue),
      errorMessage,
    };
  } catch (error) {
    return {
      isValid: false,
      data: trimmedValue,
      errorMessage,
    };
  }
};

export const validateNumber = (value: any, errorMessage?: string) => {
  const trimmedValue =
    typeof value === 'string' ? value.trim() : value.toString();

  try {
    const isValid = !isNaN(parseFloat(trimmedValue));
    return {
      isValid,
      data: parseFloat(trimmedValue),
      errorMessage,
    };
  } catch (error) {
    return {
      isValid: false,
      data: trimmedValue,
      errorMessage,
    };
  }
};

export const validateOptionalNumber = (value: any, errorMessage?: string) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;

  if (trimmedValue == '' || parseFloat(trimmedValue) >= 0) {
    return {
      isValid: true,
      data: trimmedValue == '' ? 0 : parseFloat(trimmedValue),
      errorMessage,
    };
  } else {
    return {
      isValid: false,
      data: trimmedValue,
      errorMessage,
    };
  }
};

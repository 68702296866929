// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/lib/reportUtilities.ts\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/lib/reportUtilities.ts");
  import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { format, parse } from 'date-fns';
import { validateDateFormat, validateNumber, validateRequiredString } from './validations';
export const GENDER_VALUES = ['Male', 'Female', 'Not Disclosed'];
export const ENTITY_TYPE = ['Plants', 'Offices', 'Branches'];
export const YESNO = ['Yes', 'No'];
export const YESNONA = ['Yes', 'No', 'Not Applicable'];
export const EXTERNALINTERNAL = ['Independent External', 'Internal'];
export const RECALL_TYPE = ['Voluntary', 'Forced'];
export const EMPLOYEE_TYPE = ['Permanent', 'Temporary', 'Non Guaranted Hours', 'Full Time', 'Part Time'];
export const CABINCLASS = ['Economy', 'Premium Economy', 'Business', 'First'];
export const DESIGNATION_CATEGORY = ['Board', 'Senior Management', 'Staff', 'Worker', 'DEPUTY MANAGER', 'MANAGER', 'VICE PRESIDENT', 'ASSISTANT MANAGER', 'JOINT MANAGING DIRECTOR', 'SENIOR MANAGER', 'SENIOR EXECUTIVE', 'JOINT MANAGER', 'EXECUTIVE', 'GENERAL MANAGER', 'FIRST OFFICER', 'OPERATIONAL ASSISTANT', 'ECOMMERCE AND COURIER SPECIALIST', 'ASSISTANT', 'PILOT', 'LINE MANAGER', 'LABOUR', 'LIGHT MOTOR DRIVER', 'ASSISTANT VICE PRESIDENT', 'FUNCTIONAL ASSISTANT', 'MANAGING DIRECTOR', 'SENIOR ASSISTANT', 'DIRECTOR', 'CHAIRMAN', 'PRESIDENT', 'EXECUTIVE MANAGER', 'CHIEF OPERATING OFFICER', 'CHIEF INFORMATION OFFICER', 'CHIEF FINANCIAL OFFICER', 'ASSISTANT GENERAL MANAGER', 'FORKLIFT OPERATOR', 'HEAVY DUTY DRIVER', 'TALLY CLERK', 'LINE CAPTAIN', 'CABIN CREW', 'CHIEF PILOT_LINE TRAINING CAPTAIN', 'SECURITY GUARD', 'OFFICE ASSISTANT', 'CEO', 'DEPUTY GENERAL MANAGER', 'CHIEF EXECUTIVE OFFICER', 'COMPANY SECRETARY_CHIEF GOVERNANCE OFFICER', 'EXECUTIVE CHAIRMAN', 'PACKER', 'MANAGEMENT TRAINEE', 'GRADUATE TRAINEE', 'BRANCH MANAGER', 'SUPERINTENDENT', 'Management'];
export const INTERNAL_RATING = ['Laggard', 'Below Average', 'Average', 'Best in Class', 'Leader'];
export const EXTERNAL_RATING_AGENCY = ['S&P', 'Fitch', 'Moodys', 'Capital Intelligence', 'CRISIL', 'ICRA', 'CARE', 'MSCI'];
export const TRAINING_CATEGORY = ['Health& Safety Measures', 'Skill Upgradation', 'human rights issue', 'policies', 'Awareness', 'Others'];
export const TRAINING_DETAILS = ['Employee', 'Value chain partners'];
export const TRAINING_TYPE = ['Internal', 'External'];
export const TRAINING_MODE = ['Online', 'Face to Face'];
export const COMPLAINTANT_GROUP = ['Communities', 'Investors', 'Shareholders', 'Employees or Workers', 'Customers', 'Others', 'Value chain partners'];
export const WORKERS_VALUE_CHAIN = ['Conflict of Interest', 'Health&Safety', 'Working Condition', 'Child Labour', 'Sexual Harassment', 'Discremination at workplace', 'Forced Labour', 'Wages', 'Others'];
export const CUSTOMERS = ['Data Privacy', 'Advertising', 'Cyber-Security', 'Delivery of essential Services', 'Restrictive Trade Practices', 'Unfair Trade Practices', 'Others'];
export const COMPANY_BREIF_ALL = ['Conflict of Interest', 'Health&Safety', 'Working Condition', 'Child Labour', 'Sexual Harassment', 'Discremination at workplace', 'Forced Labour', 'Wages', 'Data Privacy', 'Advertising', 'Cyber-Security', 'Delivery of essential Services', 'Restrictive Trade Practices', 'Unfair Trade Practices', 'Others'];
export const OPEN_CLOSE = ['Open', 'Close'];
export const COMPALINT_CATEGORY = ['Monetary', 'Non-Monetary'];
export const MONETARY_LIST = ['Penalty / Fines', 'Settlement', 'Compouding Fee'];
export const NON_MONETARY_LIST = ['Imprisonment', 'Punishment'];
export const REJECT_ACCEPT = ['Rejected', 'Accepted'];
export const WORK_INJURY = ['Fatality', 'High consequence'];
export const COMMUNICATION_CHANNEL = ['E-mail', 'SMS', 'Newspaper', 'Pamphlets', 'Advertisement', 'Community Meetings', 'Notice Board', 'Website', 'Other'];
export const FREQ_OF_ENGAGEMENT = ['Annual', 'Semi-annual', 'Quarterly', 'Monthly', 'Ongoing'];
export const MONTH_NAMES = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};
export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const MONTHS_OPTIONS = Object.entries(MONTH_NAMES).map(_c = ([key, value]) => ({
  label: value,
  value: Number(key)
}));
_c2 = MONTHS_OPTIONS;
export const getMonthName = idx => {
  return MONTH_NAMES?.[idx] || '';
};
export const REPORT_UNIT_VALUES = [{
  code: 'mt',
  name: 'Metric Tonnes'
}, {
  code: 'gal',
  name: 'Gallons'
}, {
  code: 'scf',
  name: 'Standard Cubic Foot'
}, {
  code: 'kg',
  name: 'kilogram'
}, {
  code: 'NM',
  name: 'Nautical Miles'
}];
export const REPORT_UNIT_CODES = REPORT_UNIT_VALUES.map(_c3 = item => item.code);
_c4 = REPORT_UNIT_CODES;
export const getUnitName = code => {
  const unit = REPORT_UNIT_VALUES.find(item => item.code === code);
  return unit ? unit.name : '';
};
export const LEGAL_FORMS_LIST = ['Sole Proprietorship', 'Limited Liability Company', 'Partnership', 'Private Limited Company', 'Public Joint Stock Company', 'Non - Profit Organization', 'Association', 'Freezone Enterprise (FZE)', 'Freezone Company (FZC)'];
export const GHG_PROTOCOLS_LIST = ['Equity Share Approach', 'Financial Control', 'Operational Control', 'NA'];
export const ASSOCIATION_LIST = ['Subsidiary', 'Associated', 'Affiliated', 'Non-Incorporated JV', 'Partnershsip', 'FA Investment', 'Francise', 'Branch'];
export const SECTOR_LIST = ['Oil and gas', 'Coal', 'Agriculture, aquaculture, and fishing', 'Mining', 'Food and beverages', 'Textiles and apparel', 'Banking', 'Insurance', 'Capital markets', 'Utilities', 'Renewable energy', 'Forestry', 'Metal processing', 'Construction materials', 'Aerospace and defense', 'Automotive', 'Construction', 'Chemicals', 'Machinery and equipment', 'Pharmaceuticals', 'Electronics', 'Media and communication', 'Software', 'Real estate', 'Transportation infrastructure', 'Shipping', 'Trucking', 'Airlines', 'Trading, distribution, and logistics', 'Packaging', 'Hotels', 'Educational services', 'Household durables', 'Managed health care', 'Medical equipment and services', 'Retail', 'Security services and correctional facilities', 'Restaurants', 'Commercial services', 'Non-profit organizations', 'SSLL-Ship Owning & Chartering', 'FZE-Logistics', 'DWC-Freight Forwarding'];
export const COUNTRIES_LIST = [{
  name: 'Afghanistan',
  dialCode: '+93',
  code: 'AF'
}, {
  name: 'Aland Islands',
  dialCode: '+358',
  code: 'AX'
}, {
  name: 'Albania',
  dialCode: '+355',
  code: 'AL'
}, {
  name: 'Algeria',
  dialCode: '+213',
  code: 'DZ'
}, {
  name: 'AmericanSamoa',
  dialCode: '+1684',
  code: 'AS'
}, {
  name: 'Andorra',
  dialCode: '+376',
  code: 'AD'
}, {
  name: 'Angola',
  dialCode: '+244',
  code: 'AO'
}, {
  name: 'Anguilla',
  dialCode: '+1264',
  code: 'AI'
}, {
  name: 'Antarctica',
  dialCode: '+672',
  code: 'AQ'
}, {
  name: 'Antigua and Barbuda',
  dialCode: '+1268',
  code: 'AG'
}, {
  name: 'Argentina',
  dialCode: '+54',
  code: 'AR'
}, {
  name: 'Armenia',
  dialCode: '+374',
  code: 'AM'
}, {
  name: 'Aruba',
  dialCode: '+297',
  code: 'AW'
}, {
  name: 'Australia',
  dialCode: '+61',
  code: 'AU'
}, {
  name: 'Austria',
  dialCode: '+43',
  code: 'AT'
}, {
  name: 'Azerbaijan',
  dialCode: '+994',
  code: 'AZ'
}, {
  name: 'Bahamas',
  dialCode: '+1242',
  code: 'BS'
}, {
  name: 'Bahrain',
  dialCode: '+973',
  code: 'BH'
}, {
  name: 'Bangladesh',
  dialCode: '+880',
  code: 'BD'
}, {
  name: 'Barbados',
  dialCode: '+1246',
  code: 'BB'
}, {
  name: 'Belarus',
  dialCode: '+375',
  code: 'BY'
}, {
  name: 'Belgium',
  dialCode: '+32',
  code: 'BE'
}, {
  name: 'Belize',
  dialCode: '+501',
  code: 'BZ'
}, {
  name: 'Benin',
  dialCode: '+229',
  code: 'BJ'
}, {
  name: 'Bermuda',
  dialCode: '+1441',
  code: 'BM'
}, {
  name: 'Bhutan',
  dialCode: '+975',
  code: 'BT'
}, {
  name: 'Bolivia, Plurinational State of bolivia',
  dialCode: '+591',
  code: 'BO'
}, {
  name: 'Bosnia and Herzegovina',
  dialCode: '+387',
  code: 'BA'
}, {
  name: 'Botswana',
  dialCode: '+267',
  code: 'BW'
}, {
  name: 'Brazil',
  dialCode: '+55',
  code: 'BR'
}, {
  name: 'British Indian Ocean Territory',
  dialCode: '+246',
  code: 'IO'
}, {
  name: 'Brunei Darussalam',
  dialCode: '+673',
  code: 'BN'
}, {
  name: 'Bulgaria',
  dialCode: '+359',
  code: 'BG'
}, {
  name: 'Burkina Faso',
  dialCode: '+226',
  code: 'BF'
}, {
  name: 'Burundi',
  dialCode: '+257',
  code: 'BI'
}, {
  name: 'Cambodia',
  dialCode: '+855',
  code: 'KH'
}, {
  name: 'Cameroon',
  dialCode: '+237',
  code: 'CM'
}, {
  name: 'Canada',
  dialCode: '+1',
  code: 'CA'
}, {
  name: 'Cape Verde',
  dialCode: '+238',
  code: 'CV'
}, {
  name: 'Cayman Islands',
  dialCode: '+ 345',
  code: 'KY'
}, {
  name: 'Central African Republic',
  dialCode: '+236',
  code: 'CF'
}, {
  name: 'Chad',
  dialCode: '+235',
  code: 'TD'
}, {
  name: 'Chile',
  dialCode: '+56',
  code: 'CL'
}, {
  name: 'China',
  dialCode: '+86',
  code: 'CN'
}, {
  name: 'Christmas Island',
  dialCode: '+61',
  code: 'CX'
}, {
  name: 'Cocos (Keeling) Islands',
  dialCode: '+61',
  code: 'CC'
}, {
  name: 'Colombia',
  dialCode: '+57',
  code: 'CO'
}, {
  name: 'Comoros',
  dialCode: '+269',
  code: 'KM'
}, {
  name: 'Congo',
  dialCode: '+242',
  code: 'CG'
}, {
  name: 'Congo, The Democratic Republic of the Congo',
  dialCode: '+243',
  code: 'CD'
}, {
  name: 'Cook Islands',
  dialCode: '+682',
  code: 'CK'
}, {
  name: 'Costa Rica',
  dialCode: '+506',
  code: 'CR'
}, {
  name: "Cote d'Ivoire",
  dialCode: '+225',
  code: 'CI'
}, {
  name: 'Croatia',
  dialCode: '+385',
  code: 'HR'
}, {
  name: 'Cuba',
  dialCode: '+53',
  code: 'CU'
}, {
  name: 'Cyprus',
  dialCode: '+357',
  code: 'CY'
}, {
  name: 'Czech Republic',
  dialCode: '+420',
  code: 'CZ'
}, {
  name: 'Denmark',
  dialCode: '+45',
  code: 'DK'
}, {
  name: 'Djibouti',
  dialCode: '+253',
  code: 'DJ'
}, {
  name: 'Dominica',
  dialCode: '+1767',
  code: 'DM'
}, {
  name: 'Dominican Republic',
  dialCode: '+1849',
  code: 'DO'
}, {
  name: 'Ecuador',
  dialCode: '+593',
  code: 'EC'
}, {
  name: 'Egypt',
  dialCode: '+20',
  code: 'EG'
}, {
  name: 'El Salvador',
  dialCode: '+503',
  code: 'SV'
}, {
  name: 'Equatorial Guinea',
  dialCode: '+240',
  code: 'GQ'
}, {
  name: 'Eritrea',
  dialCode: '+291',
  code: 'ER'
}, {
  name: 'Estonia',
  dialCode: '+372',
  code: 'EE'
}, {
  name: 'Ethiopia',
  dialCode: '+251',
  code: 'ET'
}, {
  name: 'Falkland Islands (Malvinas)',
  dialCode: '+500',
  code: 'FK'
}, {
  name: 'Faroe Islands',
  dialCode: '+298',
  code: 'FO'
}, {
  name: 'Fiji',
  dialCode: '+679',
  code: 'FJ'
}, {
  name: 'Finland',
  dialCode: '+358',
  code: 'FI'
}, {
  name: 'France',
  dialCode: '+33',
  code: 'FR'
}, {
  name: 'French Guiana',
  dialCode: '+594',
  code: 'GF'
}, {
  name: 'French Polynesia',
  dialCode: '+689',
  code: 'PF'
}, {
  name: 'Gabon',
  dialCode: '+241',
  code: 'GA'
}, {
  name: 'Gambia',
  dialCode: '+220',
  code: 'GM'
}, {
  name: 'Georgia',
  dialCode: '+995',
  code: 'GE'
}, {
  name: 'Germany',
  dialCode: '+49',
  code: 'DE'
}, {
  name: 'Ghana',
  dialCode: '+233',
  code: 'GH'
}, {
  name: 'Gibraltar',
  dialCode: '+350',
  code: 'GI'
}, {
  name: 'Greece',
  dialCode: '+30',
  code: 'GR'
}, {
  name: 'Greenland',
  dialCode: '+299',
  code: 'GL'
}, {
  name: 'Grenada',
  dialCode: '+1473',
  code: 'GD'
}, {
  name: 'Guadeloupe',
  dialCode: '+590',
  code: 'GP'
}, {
  name: 'Guam',
  dialCode: '+1671',
  code: 'GU'
}, {
  name: 'Guatemala',
  dialCode: '+502',
  code: 'GT'
}, {
  name: 'Guernsey',
  dialCode: '+44',
  code: 'GG'
}, {
  name: 'Guinea',
  dialCode: '+224',
  code: 'GN'
}, {
  name: 'Guinea-Bissau',
  dialCode: '+245',
  code: 'GW'
}, {
  name: 'Guyana',
  dialCode: '+592',
  code: 'GY'
}, {
  name: 'Haiti',
  dialCode: '+509',
  code: 'HT'
}, {
  name: 'Holy See (Vatican City State)',
  dialCode: '+379',
  code: 'VA'
}, {
  name: 'Honduras',
  dialCode: '+504',
  code: 'HN'
}, {
  name: 'Hong Kong',
  dialCode: '+852',
  code: 'HK'
}, {
  name: 'Hungary',
  dialCode: '+36',
  code: 'HU'
}, {
  name: 'Iceland',
  dialCode: '+354',
  code: 'IS'
}, {
  name: 'India',
  dialCode: '+91',
  code: 'IN'
}, {
  name: 'Indonesia',
  dialCode: '+62',
  code: 'ID'
}, {
  name: 'Iran, Islamic Republic of Persian Gulf',
  dialCode: '+98',
  code: 'IR'
}, {
  name: 'Iraq',
  dialCode: '+964',
  code: 'IQ'
}, {
  name: 'Ireland',
  dialCode: '+353',
  code: 'IE'
}, {
  name: 'Isle of Man',
  dialCode: '+44',
  code: 'IM'
}, {
  name: 'Italy',
  dialCode: '+39',
  code: 'IT'
}, {
  name: 'Jamaica',
  dialCode: '+1876',
  code: 'JM'
}, {
  name: 'Japan',
  dialCode: '+81',
  code: 'JP'
}, {
  name: 'Jersey',
  dialCode: '+44',
  code: 'JE'
}, {
  name: 'Jordan',
  dialCode: '+962',
  code: 'JO'
}, {
  name: 'Kazakhstan',
  dialCode: '+7',
  code: 'KZ'
}, {
  name: 'Kenya',
  dialCode: '+254',
  code: 'KE'
}, {
  name: 'Kiribati',
  dialCode: '+686',
  code: 'KI'
}, {
  name: "Korea, Democratic People's Republic of Korea",
  dialCode: '+850',
  code: 'KP'
}, {
  name: 'Korea, Republic of South Korea',
  dialCode: '+82',
  code: 'KR'
}, {
  name: 'Kuwait',
  dialCode: '+965',
  code: 'KW'
}, {
  name: 'Kyrgyzstan',
  dialCode: '+996',
  code: 'KG'
}, {
  name: 'Laos',
  dialCode: '+856',
  code: 'LA'
}, {
  name: 'Latvia',
  dialCode: '+371',
  code: 'LV'
}, {
  name: 'Lebanon',
  dialCode: '+961',
  code: 'LB'
}, {
  name: 'Lesotho',
  dialCode: '+266',
  code: 'LS'
}, {
  name: 'Liberia',
  dialCode: '+231',
  code: 'LR'
}, {
  name: 'Libyan Arab Jamahiriya',
  dialCode: '+218',
  code: 'LY'
}, {
  name: 'Liechtenstein',
  dialCode: '+423',
  code: 'LI'
}, {
  name: 'Lithuania',
  dialCode: '+370',
  code: 'LT'
}, {
  name: 'Luxembourg',
  dialCode: '+352',
  code: 'LU'
}, {
  name: 'Macao',
  dialCode: '+853',
  code: 'MO'
}, {
  name: 'Macedonia',
  dialCode: '+389',
  code: 'MK'
}, {
  name: 'Madagascar',
  dialCode: '+261',
  code: 'MG'
}, {
  name: 'Malawi',
  dialCode: '+265',
  code: 'MW'
}, {
  name: 'Malaysia',
  dialCode: '+60',
  code: 'MY'
}, {
  name: 'Maldives',
  dialCode: '+960',
  code: 'MV'
}, {
  name: 'Mali',
  dialCode: '+223',
  code: 'ML'
}, {
  name: 'Malta',
  dialCode: '+356',
  code: 'MT'
}, {
  name: 'Marshall Islands',
  dialCode: '+692',
  code: 'MH'
}, {
  name: 'Martinique',
  dialCode: '+596',
  code: 'MQ'
}, {
  name: 'Mauritania',
  dialCode: '+222',
  code: 'MR'
}, {
  name: 'Mauritius',
  dialCode: '+230',
  code: 'MU'
}, {
  name: 'Mayotte',
  dialCode: '+262',
  code: 'YT'
}, {
  name: 'Mexico',
  dialCode: '+52',
  code: 'MX'
}, {
  name: 'Micronesia, Federated States of Micronesia',
  dialCode: '+691',
  code: 'FM'
}, {
  name: 'Moldova',
  dialCode: '+373',
  code: 'MD'
}, {
  name: 'Monaco',
  dialCode: '+377',
  code: 'MC'
}, {
  name: 'Mongolia',
  dialCode: '+976',
  code: 'MN'
}, {
  name: 'Montenegro',
  dialCode: '+382',
  code: 'ME'
}, {
  name: 'Montserrat',
  dialCode: '+1664',
  code: 'MS'
}, {
  name: 'Morocco',
  dialCode: '+212',
  code: 'MA'
}, {
  name: 'Mozambique',
  dialCode: '+258',
  code: 'MZ'
}, {
  name: 'Myanmar',
  dialCode: '+95',
  code: 'MM'
}, {
  name: 'Namibia',
  dialCode: '+264',
  code: 'NA'
}, {
  name: 'Nauru',
  dialCode: '+674',
  code: 'NR'
}, {
  name: 'Nepal',
  dialCode: '+977',
  code: 'NP'
}, {
  name: 'Netherlands',
  dialCode: '+31',
  code: 'NL'
}, {
  name: 'Netherlands Antilles',
  dialCode: '+599',
  code: 'AN'
}, {
  name: 'New Caledonia',
  dialCode: '+687',
  code: 'NC'
}, {
  name: 'New Zealand',
  dialCode: '+64',
  code: 'NZ'
}, {
  name: 'Nicaragua',
  dialCode: '+505',
  code: 'NI'
}, {
  name: 'Niger',
  dialCode: '+227',
  code: 'NE'
}, {
  name: 'Nigeria',
  dialCode: '+234',
  code: 'NG'
}, {
  name: 'Niue',
  dialCode: '+683',
  code: 'NU'
}, {
  name: 'Norfolk Island',
  dialCode: '+672',
  code: 'NF'
}, {
  name: 'Northern Mariana Islands',
  dialCode: '+1670',
  code: 'MP'
}, {
  name: 'Norway',
  dialCode: '+47',
  code: 'NO'
}, {
  name: 'Oman',
  dialCode: '+968',
  code: 'OM'
}, {
  name: 'Pakistan',
  dialCode: '+92',
  code: 'PK'
}, {
  name: 'Palau',
  dialCode: '+680',
  code: 'PW'
}, {
  name: 'Palestinian Territory, Occupied',
  dialCode: '+970',
  code: 'PS'
}, {
  name: 'Panama',
  dialCode: '+507',
  code: 'PA'
}, {
  name: 'Papua New Guinea',
  dialCode: '+675',
  code: 'PG'
}, {
  name: 'Paraguay',
  dialCode: '+595',
  code: 'PY'
}, {
  name: 'Peru',
  dialCode: '+51',
  code: 'PE'
}, {
  name: 'Philippines',
  dialCode: '+63',
  code: 'PH'
}, {
  name: 'Pitcairn',
  dialCode: '+64',
  code: 'PN'
}, {
  name: 'Poland',
  dialCode: '+48',
  code: 'PL'
}, {
  name: 'Portugal',
  dialCode: '+351',
  code: 'PT'
}, {
  name: 'Puerto Rico',
  dialCode: '+1939',
  code: 'PR'
}, {
  name: 'Qatar',
  dialCode: '+974',
  code: 'QA'
}, {
  name: 'Romania',
  dialCode: '+40',
  code: 'RO'
}, {
  name: 'Russia',
  dialCode: '+7',
  code: 'RU'
}, {
  name: 'Rwanda',
  dialCode: '+250',
  code: 'RW'
}, {
  name: 'Reunion',
  dialCode: '+262',
  code: 'RE'
}, {
  name: 'Saint Barthelemy',
  dialCode: '+590',
  code: 'BL'
}, {
  name: 'Saint Helena, Ascension and Tristan Da Cunha',
  dialCode: '+290',
  code: 'SH'
}, {
  name: 'Saint Kitts and Nevis',
  dialCode: '+1869',
  code: 'KN'
}, {
  name: 'Saint Lucia',
  dialCode: '+1758',
  code: 'LC'
}, {
  name: 'Saint Martin',
  dialCode: '+590',
  code: 'MF'
}, {
  name: 'Saint Pierre and Miquelon',
  dialCode: '+508',
  code: 'PM'
}, {
  name: 'Saint Vincent and the Grenadines',
  dialCode: '+1784',
  code: 'VC'
}, {
  name: 'Samoa',
  dialCode: '+685',
  code: 'WS'
}, {
  name: 'San Marino',
  dialCode: '+378',
  code: 'SM'
}, {
  name: 'Sao Tome and Principe',
  dialCode: '+239',
  code: 'ST'
}, {
  name: 'Saudi Arabia',
  dialCode: '+966',
  code: 'SA'
}, {
  name: 'Senegal',
  dialCode: '+221',
  code: 'SN'
}, {
  name: 'Serbia',
  dialCode: '+381',
  code: 'RS'
}, {
  name: 'Seychelles',
  dialCode: '+248',
  code: 'SC'
}, {
  name: 'Sierra Leone',
  dialCode: '+232',
  code: 'SL'
}, {
  name: 'Singapore',
  dialCode: '+65',
  code: 'SG'
}, {
  name: 'Slovakia',
  dialCode: '+421',
  code: 'SK'
}, {
  name: 'Slovenia',
  dialCode: '+386',
  code: 'SI'
}, {
  name: 'Solomon Islands',
  dialCode: '+677',
  code: 'SB'
}, {
  name: 'Somalia',
  dialCode: '+252',
  code: 'SO'
}, {
  name: 'South Africa',
  dialCode: '+27',
  code: 'ZA'
}, {
  name: 'South Sudan',
  dialCode: '+211',
  code: 'SS'
}, {
  name: 'South Georgia and the South Sandwich Islands',
  dialCode: '+500',
  code: 'GS'
}, {
  name: 'Spain',
  dialCode: '+34',
  code: 'ES'
}, {
  name: 'Sri Lanka',
  dialCode: '+94',
  code: 'LK'
}, {
  name: 'Sudan',
  dialCode: '+249',
  code: 'SD'
}, {
  name: 'Suriname',
  dialCode: '+597',
  code: 'SR'
}, {
  name: 'Svalbard and Jan Mayen',
  dialCode: '+47',
  code: 'SJ'
}, {
  name: 'Swaziland',
  dialCode: '+268',
  code: 'SZ'
}, {
  name: 'Sweden',
  dialCode: '+46',
  code: 'SE'
}, {
  name: 'Switzerland',
  dialCode: '+41',
  code: 'CH'
}, {
  name: 'Syrian Arab Republic',
  dialCode: '+963',
  code: 'SY'
}, {
  name: 'Taiwan',
  dialCode: '+886',
  code: 'TW'
}, {
  name: 'Tajikistan',
  dialCode: '+992',
  code: 'TJ'
}, {
  name: 'Tanzania, United Republic of Tanzania',
  dialCode: '+255',
  code: 'TZ'
}, {
  name: 'Thailand',
  dialCode: '+66',
  code: 'TH'
}, {
  name: 'Timor-Leste',
  dialCode: '+670',
  code: 'TL'
}, {
  name: 'Togo',
  dialCode: '+228',
  code: 'TG'
}, {
  name: 'Tokelau',
  dialCode: '+690',
  code: 'TK'
}, {
  name: 'Tonga',
  dialCode: '+676',
  code: 'TO'
}, {
  name: 'Trinidad and Tobago',
  dialCode: '+1868',
  code: 'TT'
}, {
  name: 'Tunisia',
  dialCode: '+216',
  code: 'TN'
}, {
  name: 'Turkey',
  dialCode: '+90',
  code: 'TR'
}, {
  name: 'Turkmenistan',
  dialCode: '+993',
  code: 'TM'
}, {
  name: 'Turks and Caicos Islands',
  dialCode: '+1649',
  code: 'TC'
}, {
  name: 'Tuvalu',
  dialCode: '+688',
  code: 'TV'
}, {
  name: 'Uganda',
  dialCode: '+256',
  code: 'UG'
}, {
  name: 'Ukraine',
  dialCode: '+380',
  code: 'UA'
}, {
  name: 'United Arab Emirates',
  dialCode: '+971',
  code: 'AE'
}, {
  name: 'United Kingdom',
  dialCode: '+44',
  code: 'GB'
}, {
  name: 'United States',
  dialCode: '+1',
  code: 'US'
}, {
  name: 'Uruguay',
  dialCode: '+598',
  code: 'UY'
}, {
  name: 'Uzbekistan',
  dialCode: '+998',
  code: 'UZ'
}, {
  name: 'Vanuatu',
  dialCode: '+678',
  code: 'VU'
}, {
  name: 'Venezuela, Bolivarian Republic of Venezuela',
  dialCode: '+58',
  code: 'VE'
}, {
  name: 'Vietnam',
  dialCode: '+84',
  code: 'VN'
}, {
  name: 'Virgin Islands, British',
  dialCode: '+1284',
  code: 'VG'
}, {
  name: 'Virgin Islands, U.S.',
  dialCode: '+1340',
  code: 'VI'
}, {
  name: 'Wallis and Futuna',
  dialCode: '+681',
  code: 'WF'
}, {
  name: 'Yemen',
  dialCode: '+967',
  code: 'YE'
}, {
  name: 'Zambia',
  dialCode: '+260',
  code: 'ZM'
}, {
  name: 'Zimbabwe',
  dialCode: '+263',
  code: 'ZW'
}];
export const COUNTRIES_ALPHA2_LIST = COUNTRIES_LIST.map(_c5 = country => country.code || country.name);
_c6 = COUNTRIES_ALPHA2_LIST;
export const getCountryName = code => {
  const country = COUNTRIES_LIST.find(item => item.code === code);
  return country ? country.name : '';
};
export const CURRENCY_LIST = [{
  enabled: true,
  active: true,
  name: 'Afghani',
  code: 'AFN'
}, {
  enabled: true,
  active: true,
  name: 'Lek',
  code: 'ALL'
}, {
  enabled: true,
  active: true,
  name: 'Algerian Dinar',
  code: 'DZD'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Kwanza',
  code: 'AOA'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'Argentine Peso',
  code: 'ARS'
}, {
  enabled: true,
  active: true,
  name: 'Armenian Dram',
  code: 'AMD'
}, {
  enabled: true,
  active: true,
  name: 'Aruban Florin',
  code: 'AWG'
}, {
  enabled: true,
  active: true,
  name: 'Australian Dollar',
  code: 'AUD'
}, {
  enabled: true,
  active: true,
  name: 'Azerbaijanian Manat',
  code: 'AZN'
}, {
  enabled: true,
  active: true,
  name: 'Bahamian Dollar',
  code: 'BSD'
}, {
  enabled: true,
  active: true,
  name: 'Bahraini Dinar',
  code: 'BHD'
}, {
  enabled: true,
  active: true,
  name: 'Taka',
  code: 'BDT'
}, {
  enabled: true,
  active: true,
  name: 'Barbados Dollar',
  code: 'BBD'
}, {
  enabled: true,
  active: true,
  name: 'Belarussian Ruble',
  code: 'BYN'
}, {
  enabled: true,
  active: true,
  name: 'Belize Dollar',
  code: 'BZD'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BCEAO',
  code: 'XOF'
}, {
  enabled: true,
  active: true,
  name: 'Bermudian Dollar',
  code: 'BMD'
}, {
  enabled: true,
  active: true,
  name: 'Ngultrum',
  code: 'BTN'
}, {
  enabled: true,
  active: true,
  name: 'Indian Rupee',
  code: 'INR'
}, {
  enabled: true,
  active: true,
  name: 'Boliviano',
  code: 'BOB'
}, {
  enabled: true,
  active: true,
  name: 'Mvdol',
  code: 'BOV'
}, {
  enabled: true,
  active: true,
  name: 'Convertible Mark',
  code: 'BAM'
}, {
  enabled: true,
  active: true,
  name: 'Pula',
  code: 'BWP'
}, {
  enabled: true,
  active: true,
  name: 'Norwegian Krone',
  code: 'NOK'
}, {
  enabled: true,
  active: true,
  name: 'Brazilian Real',
  code: 'BRL'
}, {
  enabled: true,
  active: true,
  name: 'Brunei Dollar',
  code: 'BND'
}, {
  enabled: true,
  active: true,
  name: 'Bulgarian Lev',
  code: 'BGN'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'Burundi Franc',
  code: 'BIF'
}, {
  enabled: true,
  active: true,
  name: 'Cabo Verde Escudo',
  code: 'CVE'
}, {
  enabled: true,
  active: true,
  name: 'Riel',
  code: 'KHR'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'Canadian Dollar',
  code: 'CAD'
}, {
  enabled: true,
  active: true,
  name: 'Cayman Islands Dollar',
  code: 'KYD'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'CLF',
  code: 'CLF'
}, {
  enabled: true,
  active: true,
  name: 'Chilean Peso',
  code: 'CLP'
}, {
  enabled: true,
  active: true,
  name: 'Yuan Renminbi',
  code: 'CNY'
}, {
  enabled: true,
  active: true,
  name: 'Australian Dollar',
  code: 'AUD'
}, {
  enabled: true,
  active: true,
  name: 'Australian Dollar',
  code: 'AUD'
}, {
  enabled: true,
  active: true,
  name: 'Colombian Peso',
  code: 'COP'
}, {
  enabled: true,
  active: true,
  name: 'Unidad de Valor Real',
  code: 'COU'
}, {
  enabled: true,
  active: true,
  name: 'Comoro Franc',
  code: 'KMF'
}, {
  enabled: true,
  active: true,
  name: 'Congolese Franc',
  code: 'CDF'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'New Zealand Dollar',
  code: 'NZD'
}, {
  enabled: true,
  active: true,
  name: 'Costa Rican Colon',
  code: 'CRC'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Peso Convertible',
  code: 'CUC'
}, {
  enabled: true,
  active: true,
  name: 'Cuban Peso',
  code: 'CUP'
}, {
  enabled: true,
  active: true,
  name: 'Netherlands Antillean Guilder',
  code: 'ANG'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Czech Koruna',
  code: 'CZK'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BCEAO',
  code: 'XOF'
}, {
  enabled: true,
  active: true,
  name: 'Danish Krone',
  code: 'DKK'
}, {
  enabled: true,
  active: true,
  name: 'Djibouti Franc',
  code: 'DJF'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'Dominican Peso',
  code: 'DOP'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'Egyptian Pound',
  code: 'EGP'
}, {
  enabled: true,
  active: true,
  name: 'El Salvador Colon',
  code: 'SVC'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'Nakfa',
  code: 'ERN'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Ethiopian Birr',
  code: 'ETB'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Falkland Islands Pound',
  code: 'FKP'
}, {
  enabled: true,
  active: true,
  name: 'Danish Krone',
  code: 'DKK'
}, {
  enabled: true,
  active: true,
  name: 'Fiji Dollar',
  code: 'FJD'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'CFP Franc',
  code: 'XPF'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BEAC',
  code: 'XAF'
}, {
  enabled: true,
  active: true,
  name: 'Dalasi',
  code: 'GMD'
}, {
  enabled: true,
  active: true,
  name: 'Lari',
  code: 'GEL'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Ghana Cedi',
  code: 'GHS'
}, {
  enabled: true,
  active: true,
  name: 'Gibraltar Pound',
  code: 'GIP'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Danish Krone',
  code: 'DKK'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'Quetzal',
  code: 'GTQ'
}, {
  enabled: true,
  active: true,
  name: 'Pound Sterling',
  code: 'GBP'
}, {
  enabled: true,
  active: true,
  name: 'Guinea Franc',
  code: 'GNF'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BCEAO',
  code: 'XOF'
}, {
  enabled: true,
  active: true,
  name: 'Guyana Dollar',
  code: 'GYD'
}, {
  enabled: true,
  active: true,
  name: 'Gourde',
  code: 'HTG'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'Australian Dollar',
  code: 'AUD'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Lempira',
  code: 'HNL'
}, {
  enabled: true,
  active: true,
  name: 'Hong Kong Dollar',
  code: 'HKD'
}, {
  enabled: true,
  active: true,
  name: 'Forint',
  code: 'HUF'
}, {
  enabled: true,
  active: true,
  name: 'Iceland Krona',
  code: 'ISK'
}, {
  enabled: true,
  active: true,
  name: 'Indian Rupee',
  code: 'INR'
}, {
  enabled: true,
  active: true,
  name: 'Rupiah',
  code: 'IDR'
}, {
  enabled: true,
  active: true,
  name: 'SDR (Special Drawing Right)',
  code: 'XDR'
}, {
  enabled: true,
  active: true,
  name: 'Iranian Rial',
  code: 'IRR'
}, {
  enabled: true,
  active: true,
  name: 'Iraqi Dinar',
  code: 'IQD'
}, {
  enabled: true,
  active: true,
  name: 'Pound Sterling',
  code: 'GBP'
}, {
  enabled: true,
  active: true,
  name: 'New Israeli Sheqel',
  code: 'ILS'
}, {
  enabled: true,
  active: true,
  name: 'Jamaican Dollar',
  code: 'JMD'
}, {
  enabled: true,
  active: true,
  name: 'Yen',
  code: 'JPY'
}, {
  enabled: true,
  active: true,
  name: 'Jordanian Dinar',
  code: 'JOD'
}, {
  enabled: true,
  active: true,
  name: 'Tenge',
  code: 'KZT'
}, {
  enabled: true,
  active: true,
  name: 'Kenyan Shilling',
  code: 'KES'
}, {
  enabled: true,
  active: true,
  name: 'North Korean Won',
  code: 'KPW'
}, {
  enabled: true,
  active: true,
  name: 'Won',
  code: 'KRW'
}, {
  enabled: true,
  active: true,
  name: 'Kuwaiti Dinar',
  code: 'KWD'
}, {
  enabled: true,
  active: true,
  name: 'Som',
  code: 'KGS'
}, {
  enabled: true,
  active: true,
  name: 'Kip',
  code: 'LAK'
}, {
  enabled: true,
  active: true,
  name: 'Lebanese Pound',
  code: 'LBP'
}, {
  enabled: true,
  active: true,
  name: 'Loti',
  code: 'LSL'
}, {
  enabled: true,
  active: true,
  name: 'Rand',
  code: 'ZAR'
}, {
  enabled: true,
  active: true,
  name: 'Liberian Dollar',
  code: 'LRD'
}, {
  enabled: true,
  active: true,
  name: 'Libyan Dinar',
  code: 'LYD'
}, {
  enabled: true,
  active: true,
  name: 'Swiss Franc',
  code: 'CHF'
}, {
  enabled: true,
  active: true,
  name: 'Pataca',
  code: 'MOP'
}, {
  enabled: true,
  active: true,
  name: 'Malagasy Ariary',
  code: 'MGA'
}, {
  enabled: true,
  active: true,
  name: 'Kwacha',
  code: 'MWK'
}, {
  enabled: true,
  active: true,
  name: 'Malaysian Ringgit',
  code: 'MYR'
}, {
  enabled: true,
  active: true,
  name: 'Rufiyaa',
  code: 'MVR'
}, {
  enabled: true,
  active: true,
  name: 'Ouguiya',
  code: 'MRU'
}, {
  enabled: true,
  active: true,
  name: 'Mauritius Rupee',
  code: 'MUR'
}, {
  enabled: true,
  active: true,
  name: 'ADB Unit of Account',
  code: 'XUA'
}, {
  enabled: true,
  active: true,
  name: 'Mexican Peso',
  code: 'MXN'
}, {
  enabled: true,
  active: true,
  name: 'Mexican Unidad de Inversion (UDI)',
  code: 'MXV'
}, {
  enabled: true,
  active: true,
  name: 'Moldovan Leu',
  code: 'MDL'
}, {
  enabled: true,
  active: true,
  name: 'Tugrik',
  code: 'MNT'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'Moroccan Dirham',
  code: 'MAD'
}, {
  enabled: true,
  active: true,
  name: 'Mozambique Metical',
  code: 'MZN'
}, {
  enabled: true,
  active: true,
  name: 'Kyat',
  code: 'MMK'
}, {
  enabled: true,
  active: true,
  name: 'Namibia Dollar',
  code: 'NAD'
}, {
  enabled: true,
  active: true,
  name: 'Nepalese Rupee',
  code: 'NPR'
}, {
  enabled: true,
  active: true,
  name: 'CFP Franc',
  code: 'XPF'
}, {
  enabled: true,
  active: true,
  name: 'New Zealand Dollar',
  code: 'NZD'
}, {
  enabled: true,
  active: true,
  name: 'Cordoba Oro',
  code: 'NIO'
}, {
  enabled: true,
  active: true,
  name: 'Naira',
  code: 'NGN'
}, {
  enabled: true,
  active: true,
  name: 'Zloty',
  code: 'PLN'
}, {
  enabled: true,
  active: true,
  name: 'Qatari Rial',
  code: 'QAR'
}, {
  enabled: true,
  active: true,
  name: 'Denar',
  code: 'MKD'
}, {
  enabled: true,
  active: true,
  name: 'Romanian Leu',
  code: 'RON'
}, {
  enabled: true,
  active: true,
  name: 'Russian Ruble',
  code: 'RUB'
}, {
  enabled: true,
  active: true,
  name: 'Rwanda Franc',
  code: 'RWF'
}, {
  enabled: true,
  active: true,
  name: 'Saint Helena Pound',
  code: 'SHP'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'East Caribbean Dollar',
  code: 'XCD'
}, {
  enabled: true,
  active: true,
  name: 'Tala',
  code: 'WST'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Dobra',
  code: 'STN'
}, {
  enabled: true,
  active: true,
  name: 'Saudi Riyal',
  code: 'SAR'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BCEAO',
  code: 'XOF'
}, {
  enabled: true,
  active: true,
  name: 'Serbian Dinar',
  code: 'RSD'
}, {
  enabled: true,
  active: true,
  name: 'Seychelles Rupee',
  code: 'SCR'
}, {
  enabled: true,
  active: true,
  name: 'Leone',
  code: 'SLE'
}, {
  enabled: true,
  active: true,
  name: 'Singapore Dollar',
  code: 'SGD'
}, {
  enabled: true,
  active: true,
  name: 'Netherlands Antillean Guilder',
  code: 'ANG'
}, {
  enabled: true,
  active: true,
  name: 'Sucre',
  code: 'XSU'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Solomon Islands Dollar',
  code: 'SBD'
}, {
  enabled: true,
  active: true,
  name: 'Somali Shilling',
  code: 'SOS'
}, {
  enabled: true,
  active: true,
  name: 'Rand',
  code: 'ZAR'
}, {
  enabled: true,
  active: true,
  name: 'No universal currency',
  code: 'SSP'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}, {
  enabled: true,
  active: true,
  name: 'Sri Lanka Rupee',
  code: 'LKR'
}, {
  enabled: true,
  active: true,
  name: 'Sudanese Pound',
  code: 'SDG'
}, {
  enabled: true,
  active: true,
  name: 'Surinam Dollar',
  code: 'SRD'
}, {
  enabled: true,
  active: true,
  name: 'Norwegian Krone',
  code: 'NOK'
}, {
  enabled: true,
  active: true,
  name: 'Lilangeni',
  code: 'SZL'
}, {
  enabled: true,
  active: true,
  name: 'Swedish Krona',
  code: 'SEK'
}, {
  enabled: true,
  active: true,
  name: 'WIR Euro',
  code: 'CHE'
}, {
  enabled: true,
  active: true,
  name: 'Swiss Franc',
  code: 'CHF'
}, {
  enabled: true,
  active: true,
  name: 'WIR Franc',
  code: 'CHW'
}, {
  enabled: true,
  active: true,
  name: 'Syrian Pound',
  code: 'SYP'
}, {
  enabled: true,
  active: true,
  name: 'New Taiwan Dollar',
  code: 'TWD'
}, {
  enabled: true,
  active: true,
  name: 'Somoni',
  code: 'TJS'
}, {
  enabled: true,
  active: true,
  name: 'Tanzanian Shilling',
  code: 'TZS'
}, {
  enabled: true,
  active: true,
  name: 'Baht',
  code: 'THB'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'CFA Franc BCEAO',
  code: 'XOF'
}, {
  enabled: true,
  active: true,
  name: 'New Zealand Dollar',
  code: 'NZD'
}, {
  enabled: true,
  active: true,
  name: 'Pa’anga',
  code: 'TOP'
}, {
  enabled: true,
  active: true,
  name: 'Trinidad and Tobago Dollar',
  code: 'TTD'
}, {
  enabled: true,
  active: true,
  name: 'Tunisian Dinar',
  code: 'TND'
}, {
  enabled: true,
  active: true,
  name: 'Turkish Lira',
  code: 'TRY'
}, {
  enabled: true,
  active: true,
  name: 'Turkmenistan New Manat',
  code: 'TMT'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'Australian Dollar',
  code: 'AUD'
}, {
  enabled: true,
  active: true,
  name: 'Uganda Shilling',
  code: 'UGX'
}, {
  enabled: true,
  active: true,
  name: 'Hryvnia',
  code: 'UAH'
}, {
  enabled: true,
  active: true,
  name: 'UAE Dirham',
  code: 'AED'
}, {
  enabled: true,
  active: true,
  name: 'Pound Sterling',
  code: 'GBP'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar (Next day)',
  code: 'USN'
}, {
  enabled: true,
  active: true,
  name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
  code: 'UYI'
}, {
  enabled: true,
  active: true,
  name: 'Peso Uruguayo',
  code: 'UYU'
}, {
  enabled: true,
  active: true,
  name: 'Uzbekistan Sum',
  code: 'UZS'
}, {
  enabled: true,
  active: true,
  name: 'Vatu',
  code: 'VUV'
}, {
  enabled: true,
  active: true,
  name: 'Bolivar',
  code: 'VEF'
}, {
  enabled: true,
  active: true,
  name: 'Bolivar',
  code: 'VED'
}, {
  enabled: true,
  active: true,
  name: 'Dong',
  code: 'VND'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'US Dollar',
  code: 'USD'
}, {
  enabled: true,
  active: true,
  name: 'CFP Franc',
  code: 'XPF'
}, {
  enabled: true,
  active: true,
  name: 'Moroccan Dirham',
  code: 'MAD'
}, {
  enabled: true,
  active: true,
  name: 'Yemeni Rial',
  code: 'YER'
}, {
  enabled: true,
  active: true,
  name: 'Zambian Kwacha',
  code: 'ZMW'
}, {
  enabled: true,
  active: true,
  name: 'Zimbabwe Dollar',
  code: 'ZWL'
}, {
  enabled: true,
  active: true,
  name: 'Euro',
  code: 'EUR'
}];
export const CHART_COLORS = ['#69738C', '#55679C', '#7F889F', '#8B93A7', '#F5F8FA', '#4F80C1', '#959CB1', '#2B344F'];
export const formatNumberVal = (val, decimal = 2, forcedDecimal = 0) => {
  if (!val) return 0;
  if (val < 1000) return parseFloat(`${val}`).toFixed(decimal);
  if (val < 1000000) {
    return parseFloat(`${val / 1000}`)?.toFixed(decimal) + 'K';
  }
  if (val < 1000000000) {
    return parseFloat(`${val / 1000000}`)?.toFixed(forcedDecimal) + 'M';
  }
  if (val < 1000000000000) {
    return parseFloat(`${val / 1000000000}`)?.toFixed(forcedDecimal) + 'B';
  }
  if (val < 1000000000000000) {
    return parseFloat(`${val / 1000000000000}`)?.toFixed(forcedDecimal) + 'T';
  }
};
export const validateHeaderRow = (headerRow, headerColumnName) => {
  const headerValues = headerRow?.map(item => item?.trim()?.toLowerCase());
  const headerColumnValues = headerColumnName?.map(item => item?.trim()?.toLowerCase());
  if (headerValues?.length > 0 && headerColumnValues?.length > 0) {
    headerColumnValues.forEach(column => {
      if (!headerValues.includes(column)) return false;
    });
    return true;
  }
  return false;

  // return headerColumnName.every(
  //   (headerKey, headerIdx) =>
  //     `${headerRow?.[headerIdx]}`?.trim()?.toLowerCase() ===
  //     headerKey?.trim()?.toLowerCase(),
  // );
};
export const validateRows = (row, rowIndex, sheetRules, referenceValues) => {
  const rowData = sheetRules.map((rule, idx) => {
    const {
      key,
      isMandatory,
      defaultIfEmpty,
      validateFn
    } = rule;
    if (isMandatory && !row?.[idx]) {
      return {
        isValid: false,
        data: row?.[idx],
        key: `${key}`.trim()
      };
    }
    const value = row?.[idx] || defaultIfEmpty;
    const validateCell = validateFn(value, referenceValues, row);
    return {
      ...validateCell,
      key
    };
  });
  const isRowValid = rowData.every(cell => cell.isValid);
  return {
    rowIndex,
    data: rowData,
    isRowValid
  };
};
export const getCustomColumnData = (column, row) => {
  const {
    columnIndex,
    fieldKey,
    fieldType
  } = column;
  switch (fieldType) {
    case 'Date':
      {
        const validateField = validateDateFormat(row[columnIndex]);
        const formatedVal = validateField?.isValid ? validateField?.data : undefined;
        return {
          [fieldKey]: formatedVal
        };
      }
    case 'Number':
      {
        const validateField = validateNumber(row[columnIndex]);
        const formatedVal = validateField?.isValid ? validateField?.data : undefined;
        return {
          [fieldKey]: formatedVal
        };
      }
    case 'String':
      {
        const validateField = validateRequiredString(row[columnIndex]);
        const formatedVal = validateField?.isValid ? validateField?.data : undefined;
        return {
          [fieldKey]: formatedVal
        };
      }
    default:
      return null;
  }
};
export const validateBRSRRows = (row, rowIndex, sheetRules, referenceValues) => {
  const columnMappings = referenceValues?.columnMappings || {};
  const customColumnsList = columnMappings?.customColumnsList || [];
  const rowData = sheetRules.map((rule, idx) => {
    const {
      key,
      isMandatory,
      defaultIfEmpty,
      validateFn,
      fieldName
    } = rule;
    if (isMandatory && !row?.[columnMappings?.[fieldName]]) {
      return {
        isValid: false,
        data: row?.[columnMappings?.[fieldName]],
        key: `${key}`.trim()
      };
    }
    const value = row?.[columnMappings?.[fieldName]] || defaultIfEmpty;
    const validateCell = validateFn(value, referenceValues, row);
    return {
      ...validateCell,
      key,
      idx
    };
  });
  const customColumnsData = customColumnsList.map(column => getCustomColumnData(column, row))?.filter(item => !!item) || [];
  const isRowValid = rowData.every(cell => cell.isValid);
  return {
    rowIndex,
    data: rowData,
    isRowValid,
    customColumnsData,
    customColumnsList
  };
};
export const validateEmissionSheetData = async ({
  sheetData,
  sheetRules,
  sheetHeaders,
  fileYear,
  fileMonth,
  reportDateColumn
}) => {
  const errors = [];
  // validate header row
  console.log(sheetRules, 'sheetRules::');
  console.log(sheetHeaders, 'sheetHeaders::');
  const isHeaderValid = validateHeaderRow(sheetData[0], sheetHeaders);
  if (!isHeaderValid) {
    errors.push('Invalid Header Please check your file header column values and it should match with the sample file');
    return {
      errors,
      isHeaderValid
    };
  }
  // validate value cell except header
  const valuesData = sheetData.slice().splice(1);
  const rowsValidatedData = valuesData?.filter(itm => !!itm?.length)?.map((itm, rowIdx) => validateRows(itm, rowIdx + 1, sheetRules, {
    fileYear,
    fileMonth,
    reportDateColumn
  }));
  const validRows = rowsValidatedData?.filter(itm => itm?.isRowValid);
  const invalidRows = rowsValidatedData?.filter(itm => !itm?.isRowValid);
  const validRowsData = validRows?.map(itm => {
    const data = itm?.data?.reduce((acc, cell) => {
      const fieldItem = sheetRules.find(itm => itm.key === cell.key);
      if (cell.key === reportDateColumn) {
        acc['fileName'] = `${cell.data?.reportMonthName}-${cell.data?.reportYear}`;
        acc['reportDate'] = cell.data?.formatedDate;
        acc['reportDay'] = cell.data?.reportDay;
        acc['reportMonth'] = cell.data?.reportMonth;
        acc['reportYear'] = cell.data?.reportYear;
        acc['reportMonthName'] = cell.data?.reportMonthName;
        return acc;
      }
      acc[fieldItem.fieldName] = cell.data;
      return acc;
    }, {});
    return data;
  });
  return {
    errors,
    isHeaderValid,
    validRows,
    invalidRows,
    validRowsData
  };
};
export const validateSheetData = async ({
  sheetData,
  sheetRules,
  sheetHeaders,
  fileYear,
  fileMonth,
  reportDateColumn = []
}) => {
  const errors = [];
  // validate header row

  const isHeaderValid = validateHeaderRow(sheetData[0], sheetHeaders);
  if (!isHeaderValid) {
    errors.push('Invalid Header Please check your file header column values and it should match with the sample file');
    return {
      errors,
      isHeaderValid
    };
  }
  // validate value cell except header
  let valuesData = sheetData.slice().splice(1);
  const [startDateColumn, endDateColumn] = reportDateColumn;
  // Find indices for startDate and endDate based on sheetRules
  const startDateIndex = startDateColumn ? sheetRules.findIndex(itm => itm.key === startDateColumn) : -1; // If startDate is not provided or not found
  const endDateIndex = endDateColumn ? sheetRules.findIndex(itm => itm.key === endDateColumn) : -1; // If endDate is not provided or not found

  const formatEmployeeDate = valuesData?.map(row => {
    try {
      const expectedDateFormat = 'dd/MM/yyyy';

      // Handling both startDate and endDate
      const excelStartDate = new Date((row[startDateIndex] - (25567 + 2)) * 86400 * 1000);
      const formattedStartDate = format(excelStartDate, expectedDateFormat);
      const excelEndDate = new Date((row[endDateIndex] - (25567 + 2)) * 86400 * 1000);
      const formattedEndDate = format(excelEndDate, expectedDateFormat);
      const parsedStartDate = parse(formattedStartDate, 'dd/MM/yyyy', new Date());
      const parsedEndDate = parse(formattedEndDate, 'dd/MM/yyyy', new Date());
      const startYear = Number(format(parsedStartDate, 'yyyy'));
      const startMonth = Number(format(parsedStartDate, 'M'));
      const endYear = Number(format(parsedEndDate, 'yyyy'));
      const endMonth = Number(format(parsedEndDate, 'M'));

      // Compare both startDate and endDate with the fileYear and fileMonth
      if (startYear === fileYear && startMonth === fileMonth && endYear === fileYear && endMonth === fileMonth) {
        return row;
      }
      return null;
    } catch (error) {
      console.info(error);
      return null;
    }
  }).filter(item => !!item);
  valuesData = formatEmployeeDate;
  const rowsValidatedData = valuesData?.filter(itm => !!itm?.length)?.map((itm, rowIdx) => validateRows(itm, rowIdx + 1, sheetRules, {
    fileYear,
    fileMonth,
    reportDateColumn: {
      startDateColumn,
      endDateColumn
    }
  }));
  const validRows = rowsValidatedData?.filter(itm => itm?.isRowValid);
  const invalidRows = rowsValidatedData?.filter(itm => !itm?.isRowValid);
  const validRowsData = validRows?.map(itm => {
    const data = itm?.data?.reduce((acc, cell) => {
      const fieldItem = sheetRules.find(itm => itm.key === cell.key);

      // Handle startDate from reportDateColumn
      if (cell.key === startDateColumn) {
        acc['fileName'] = `${cell.data?.reportMonthName}-${cell.data?.reportYear}`;
        acc['startDate'] = cell.data?.formatedDate;
        acc['reportDay'] = cell.data?.reportDay;
        acc['reportMonth'] = cell.data?.reportMonth;
        acc['reportYear'] = cell.data?.reportYear;
        acc['reportMonthName'] = cell.data?.reportMonthName;
        return acc;
      }

      // Handle endDate from reportDateColumn
      if (cell.key === endDateColumn) {
        acc['endDate'] = cell.data?.formatedDate;
        acc['endDay'] = cell.data?.endDay;
        acc['endMonth'] = cell.data?.endMonth;
        acc['endYear'] = cell.data?.endYear;
        acc['endMonthName'] = cell.data?.endMonthName;
        return acc;
      }
      acc[fieldItem.fieldName] = cell.data;
      return acc;
    }, {});
    return data;
  });
  return {
    errors,
    isHeaderValid,
    validRows,
    invalidRows,
    validRowsData
  };
};
export const validateEmissionScope3SheetData = async ({
  sheetData,
  sheetRules,
  sheetHeaders,
  fileYear,
  fileMonth,
  referenceValues = {},
  reportDateColumn
}) => {
  const errors = [];
  // validate header row
  const isHeaderValid = validateHeaderRow(sheetData[0], sheetHeaders);
  if (!isHeaderValid) {
    errors.push('Invalid Header Please check your file header column values and it should match with the sample file');
    return {
      errors,
      isHeaderValid
    };
  }
  // validate value cell except header
  const valuesData = sheetData.slice().splice(1);
  const rowsValidatedData = valuesData?.filter(itm => !!itm?.length)?.map((itm, rowIdx) => validateRows(itm, rowIdx + 1, sheetRules, {
    fileYear,
    fileMonth,
    reportDateColumn,
    ...referenceValues
  }));
  const validRows = rowsValidatedData?.filter(itm => itm?.isRowValid);
  const invalidRows = rowsValidatedData?.filter(itm => !itm?.isRowValid);
  const validRowsData = validRows?.map(itm => {
    const data = itm?.data?.reduce((acc, cell) => {
      const fieldItem = sheetRules.find(itm => itm.key === cell.key);
      if (cell.key === reportDateColumn) {
        acc['fileName'] = `${cell.data?.reportMonthName}-${cell.data?.reportYear}`;
        acc['reportDate'] = cell.data?.formatedDate;
        acc['reportDay'] = cell.data?.reportDay;
        acc['reportMonth'] = cell.data?.reportMonth;
        acc['reportYear'] = cell.data?.reportYear;
        acc['reportMonthName'] = cell.data?.reportMonthName;
        return acc;
      }
      acc[fieldItem.fieldName] = cell.data;
      return acc;
    }, {});
    return data;
  });
  return {
    errors,
    isHeaderValid,
    validRows,
    invalidRows,
    validRowsData
  };
};
export const validateESGSheetData = async ({
  sheetData,
  sheetRules,
  sheetHeaders,
  fileYear = null,
  fileMonth = null,
  reportDateColumn = [],
  referenceValues = {},
  options = {
    isDepartmentSheet: false,
    isEmployeeSheet: false
  }
}) => {
  const errors = [];
  // Validate header row
  const isHeaderValid = validateHeaderRow(sheetData[0], sheetHeaders);
  if (!isHeaderValid) {
    errors.push('Invalid Header. Please check your file header column values and make sure they match the sample file.');
    return {
      errors,
      isHeaderValid
    };
  }

  // Validate value cell except header
  let valuesData = sheetData.slice(1).filter(itm => !!itm?.length);

  // Extract startDate and endDate from reportDateColumn array
  const [startDateColumn, endDateColumn] = reportDateColumn;
  const departmentIds = [];
  const employeedIds = [];
  const startDateIndex = sheetRules.findIndex(itm => itm.key === startDateColumn);
  const endDateIndex = sheetRules.findIndex(itm => itm.key === endDateColumn);
  if (options?.isEmployeeSheet) {
    const formatEmployeeDate = valuesData?.map(row => {
      try {
        const expectedDateFormat = 'dd/MM/yyyy';

        // Handling both startDate and endDate
        const excelStartDate = new Date((row[startDateIndex] - (25567 + 2)) * 86400 * 1000);
        const formattedStartDate = format(excelStartDate, expectedDateFormat);
        const excelEndDate = new Date((row[endDateIndex] - (25567 + 2)) * 86400 * 1000);
        const formattedEndDate = format(excelEndDate, expectedDateFormat);
        const parsedStartDate = parse(formattedStartDate, 'dd/MM/yyyy', new Date());
        const parsedEndDate = parse(formattedEndDate, 'dd/MM/yyyy', new Date());
        const startYear = Number(format(parsedStartDate, 'yyyy'));
        const startMonth = Number(format(parsedStartDate, 'M'));
        const endYear = Number(format(parsedEndDate, 'yyyy'));
        const endMonth = Number(format(parsedEndDate, 'M'));

        // Compare both startDate and endDate with the fileYear and fileMonth
        if (startYear === fileYear && startMonth === fileMonth && endYear === fileYear && endMonth === fileMonth) {
          return row;
        }
        return null;
      } catch (error) {
        console.info(error);
        return null;
      }
    }).filter(item => !!item);
    valuesData = formatEmployeeDate;
  }
  const rowsValidatedData = valuesData?.map((itm, rowIdx) => {
    const localDepartmentId = [...departmentIds];
    const localEmployeedIds = [...employeedIds];
    if (options?.isDepartmentSheet && !departmentIds?.includes(itm?.[0])) {
      const departmentId = itm?.[0] || '';
      departmentIds.push(departmentId);
    }
    if (options?.isEmployeeSheet && !employeedIds?.includes(itm?.[0])) {
      const employeeId = itm?.[0] || '';
      employeedIds.push(employeeId);
    }
    return validateRows(itm, rowIdx + 1, sheetRules, {
      departmentIds: localDepartmentId,
      employeedIds: localEmployeedIds,
      fileYear,
      fileMonth,
      reportDateColumn: {
        startDateColumn,
        endDateColumn
      },
      ...referenceValues
    });
  });
  const validRows = rowsValidatedData?.filter(itm => itm?.isRowValid);
  const invalidRows = rowsValidatedData?.filter(itm => !itm?.isRowValid);
  const validRowsData = validRows?.map(itm => {
    const data = itm?.data?.reduce((acc, cell) => {
      const fieldItem = sheetRules.find(itm => itm.key === cell.key);

      // Now, handle both startDate and endDate when populating valid rows
      if (cell.key === startDateColumn) {
        acc['fileName'] = `${cell.data?.reportMonthName}-${cell.data?.reportYear}`;
        acc['startDate'] = cell.data?.formatedDate;
        acc['reportDay'] = cell.data?.reportDay;
        acc['reportMonth'] = cell.data?.reportMonth;
        acc['reportYear'] = cell.data?.reportYear;
        acc['reportMonthName'] = cell.data?.reportMonthName;
        return acc;
      }
      if (cell.key === endDateColumn) {
        acc['endDate'] = cell.data?.formatedDate;
        acc['endDay'] = cell.data?.endDay;
        acc['endMonth'] = cell.data?.endMonth;
        acc['endYear'] = cell.data?.endYear;
        acc['endMonthName'] = cell.data?.endMonthName;
        return acc;
      }
      acc[fieldItem.fieldName] = cell.data;
      return acc;
    }, {});
    return data;
  });
  return {
    errors,
    isHeaderValid,
    validRows,
    invalidRows,
    validRowsData
  };
};
export const validateBRSRSheetData = async ({
  sheetData,
  sheetRules,
  fileYear = null,
  fileMonth = null,
  reportDateColumn = [],
  referenceValues = {},
  columnMappings = {},
  options = {
    isCompaliantRef: false,
    isEmployeeSheet: false
  }
}) => {
  const errors = [];
  // validate value cell except header
  let valuesData = sheetData.slice().splice(1)?.filter(itm => !!itm?.length);
  const compaliantRef = [];
  const employeedIds = [];
  const [startDateColumn, endDateColumn] = reportDateColumn;

  // const dateIndex = sheetRules.findIndex((itm) => itm.key === reportDateColumn);
  // First filter out all rows that is not matching selected month and year

  const filteredData = valuesData?.map(row => {
    try {
      const expectedDateFormat = 'dd/MM/yyyy';
      const excelStartDate = new Date((row[columnMappings?.['startDate']] - (25567 + 2)) * 86400 * 1000);
      const excelEndDate = new Date((row[columnMappings?.['endDate']] - (25567 + 2)) * 86400 * 1000);
      const formattedStartDate = format(excelStartDate, expectedDateFormat);
      const parsedStartDate = parse(formattedStartDate, 'dd/MM/yyyy', new Date());
      const reportStartYear = Number(format(parsedStartDate, 'yyyy'));
      const reportStartMonth = Number(format(parsedStartDate, 'M'));
      const formattedEndDate = format(excelEndDate, expectedDateFormat);
      const parsedEndDate = parse(formattedEndDate, 'dd/MM/yyyy', new Date());
      const reportEndYear = Number(format(parsedEndDate, 'yyyy'));
      const reportEndMonth = Number(format(parsedEndDate, 'M'));
      if (reportStartYear === fileYear && reportStartMonth === fileMonth && reportEndYear === fileYear && reportEndMonth === fileMonth) {
        return row;
      }
      return null;
    } catch (error) {
      console.info(error);
      return null;
    }
  }).filter(item => !!item);
  const rowsValidatedData = filteredData?.map((itm, rowIdx) => {
    const localCompaliantRef = [...compaliantRef];
    const localEmployeedIds = [...employeedIds];
    if (options?.isCompaliantRef && !compaliantRef?.includes(itm?.[columnMappings?.['complaint_ref']])) {
      const compaliantRefId = itm?.[columnMappings?.['complaint_ref']] || '';
      compaliantRef.push(compaliantRefId);
    }
    if (options?.isEmployeeSheet && !employeedIds?.includes(itm?.[columnMappings?.['emp_id']])) {
      const employeeId = itm?.[columnMappings?.['complaint_ref']] || '';
      employeedIds.push(employeeId);
    }
    return validateBRSRRows(itm, rowIdx + 1, sheetRules, {
      compaliantRef: localCompaliantRef,
      employeedIds: localEmployeedIds,
      fileYear,
      fileMonth,
      reportDateColumn: {
        startDateColumn,
        endDateColumn
      },
      columnMappings,
      ...referenceValues
    });
  });
  const validRows = rowsValidatedData?.filter(itm => itm?.isRowValid);
  const invalidRows = rowsValidatedData?.filter(itm => !itm?.isRowValid);
  const validRowsData = validRows?.map(itm => {
    const data = itm?.data?.reduce((acc, cell) => {
      const fieldItem = sheetRules.find(itm => itm.key === cell.key);
      if (cell.key === startDateColumn) {
        acc['fileName'] = `${cell.data?.reportMonthName}-${cell.data?.reportYear}`;
        acc['startDate'] = cell.data?.formatedDate;
        acc['reportDay'] = cell.data?.reportDay;
        acc['reportMonth'] = cell.data?.reportMonth;
        acc['reportYear'] = cell.data?.reportYear;
        acc['reportMonthName'] = cell.data?.reportMonthName;
        return acc;
      }
      if (cell.key === endDateColumn) {
        acc['endDate'] = cell.data?.formatedDate;
        acc['endDay'] = cell.data?.endDay;
        acc['endMonth'] = cell.data?.endMonth;
        acc['endYear'] = cell.data?.endYear;
        acc['endMonthName'] = cell.data?.endMonthName;
        return acc;
      }
      acc[fieldItem.fieldName] = cell.data;
      return acc;
    }, {});
    // customColumnsData,
    // customColumnsList,
    const customData = itm?.customColumnsData?.reduce((acc, item) => {
      return {
        ...acc,
        ...item
      };
    }, {
      ...data,
      custom_field_Config: itm?.customColumnsList
    });
    return customData;
  });
  return {
    errors,
    validRows,
    invalidRows,
    validRowsData
  };
};

// activity 
export const validateActivitySheetData = async ({
  sheetData,
  sheetRules,
  sheetHeaders,
  fileYear,
  fileMonth,
  reportDateColumn
}) => {
  const errors = [];
  const isHeaderValid = validateHeaderRow(sheetData[0], sheetHeaders);
  const [startDateColumn, endDateColumn] = reportDateColumn;
  console.log(startDateColumn, endDateColumn, 'startDateColumn, endDateColumn');
  if (!isHeaderValid) {
    errors.push('Invalid Header Please check your file header column values and it should match with the sample file');
    return {
      errors,
      isHeaderValid
    };
  }
  // validate value cell except header
  const valuesData = sheetData.slice().splice(1);
  console.log(valuesData, 'valuesData-----------------');
  const rowsValidatedData = valuesData?.filter(itm => !!itm?.length)?.map((itm, rowIdx) => validateRows(itm, rowIdx + 1, sheetRules, {
    fileYear,
    fileMonth,
    reportDateColumn: {
      startDateColumn,
      endDateColumn
    }
  }));
  console.log(rowsValidatedData, 'rowsValidatedData----------------');
  const validRows = rowsValidatedData?.filter(itm => itm?.isRowValid);
  const invalidRows = rowsValidatedData?.filter(itm => !itm?.isRowValid);
  const validRowsData = validRows?.map(itm => {
    const data = itm?.data?.reduce((acc, cell) => {
      const fieldItem = sheetRules.find(itm => itm.key === cell.key);
      if (cell.key === startDateColumn) {
        acc['fileName'] = `${cell.data?.reportMonthName}-${cell.data?.reportYear}`;
        acc['startDate'] = cell.data?.formatedDate;
        acc['reportDay'] = cell.data?.reportDay;
        acc['reportMonth'] = cell.data?.reportMonth;
        acc['reportYear'] = cell.data?.reportYear;
        acc['reportMonthName'] = cell.data?.reportMonthName;
        return acc;
      }
      if (cell.key === endDateColumn) {
        acc['endDate'] = cell.data?.formatedDate;
        acc['endDay'] = cell.data?.endDay;
        acc['endMonth'] = cell.data?.endMonth;
        acc['endYear'] = cell.data?.endYear;
        acc['endMonthName'] = cell.data?.endMonthName;
        return acc;
      }
      acc[fieldItem.fieldName] = cell.data;
      return acc;
    }, {});
    return data;
  });
  return {
    errors,
    isHeaderValid,
    validRows,
    invalidRows,
    validRowsData
  };
};
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "MONTHS_OPTIONS$Object.entries(MONTH_NAMES).map");
$RefreshReg$(_c2, "MONTHS_OPTIONS");
$RefreshReg$(_c3, "REPORT_UNIT_CODES$REPORT_UNIT_VALUES.map");
$RefreshReg$(_c4, "REPORT_UNIT_CODES");
$RefreshReg$(_c5, "COUNTRIES_ALPHA2_LIST$COUNTRIES_LIST.map");
$RefreshReg$(_c6, "COUNTRIES_ALPHA2_LIST");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;